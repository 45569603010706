<template>
  <el-card class="box-card full-screen cash-record-statistics">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <!-- <el-form-item :label="$t('创建机构')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('创建人')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('收款机构')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('收款人')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item> -->
        <el-form-item :label="$t('amount')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('creationTime')">
          <el-date-picker v-model="queryForm.amount" type="daterange" start-placeholder="start" end-placeholder="end" value-format="yyyy-MM-dd" :editable="false" :picker-options="pickerOptions" size="small" />
        </el-form-item>
        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option value="" :label="$t('all')"></el-option>
            <el-option value="0" :label="$t('to be confirmed')"></el-option>
            <el-option value="1" :label="$t('Confirmed received')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="btnStatus.isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns" v-if="loginInfo.orgType!=1">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="addRecordVisible">{{$t('add record')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="table.records" v-loading="btnStatus.isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" width="60"></el-table-column>
        <el-table-column prop="createOrgName" :label="$t('orgCreated')" min-width="160">
          <template slot-scope="scope">
            {{scope.row.createOrgName}} ({{scope.row.createOrgInfoCode}})
          </template>
        </el-table-column>
        <el-table-column prop="createName" :label="$t('creator')" min-width="160">
          <template slot-scope="scope">
            {{scope.row.createName}} {{scope.row.createSurname}} ({{scope.row.createLoginName}})
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{(scope.row.amount).formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="receiveOrg" :label="$t('Payee org')" min-width="160">
          <template slot-scope="scope">
            {{scope.row.getOrgName}} ({{scope.row.getOrgInfoCode}})
          </template>
        </el-table-column>
        <el-table-column prop="receiveMember" :label="$t('Payee')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.getName">{{scope.row.getName}} {{scope.row.getSurname}} ({{scope.row.getLoginName}})</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('status')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-warning">{{$t('to be confirmed')}}</span>
            <span v-if="scope.row.status==1" class="text-success">{{$t('Confirmed received')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" min-width="160">
          <template slot-scope="scope">
            {{$manba(scope.row.createTime).format('ymdhms')}}
          </template>
        </el-table-column>
        <el-table-column prop="affirmTime" :label="$t('acknowledging time')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.affirmTime">{{$manba(scope.row.affirmTime).format('ymdhms')}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="320" v-if="loginInfo.orgType!=1">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0&&scope.row.getOrgInfoId!=loginInfo.orgInfoId" size="mini" disabled type="primary">{{$t('to be confirmed')}}</el-button>
            <el-button v-else-if="scope.row.status==0" size="mini" @click="updateStatus(scope.row)" type="primary">{{$t('in receipt of')}}</el-button>
            <el-button v-else size="mini" disabled type="primary">{{$t('in receipt of')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="table.total" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('cashLending')" :visible.sync="isVisible" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false">
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item :label="$t('Payee org')" prop="orgInfoId">
          <el-select v-model="addForm.orgInfoId" filterable>
            <el-option v-for="(t,i) in orgList" :label="t.orgname+`(${t.orginfocode})`" :value="t.orginfoid" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('amount')" prop="amount">
          <el-input type="number" size="medium" v-model="addForm.amount" maxlength="7" />
        </el-form-item>
        <el-form-item :label="$t('remark')">
          <el-input type="textarea" size="medium" v-model="addForm.remark" />
        </el-form-item>
        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="btnStatus.isLoading" @click="onSubmit('addForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import card from '@/api/card'
import org from '@/api/org'

export default {
  data () {
    return {
      //table高度，动态设置
      queryTableHeight: String,
      queryForm: {},
      btnStatus: { isLoading: false, },
      table: {
        records: [{
          createOrg: "长虹（100000）",
          createMember: "张三(1000)",
          receiveOrg: "海尔（200000）",
          receiveMember: "",
          amount: "600000",
          status: 1,
          createTime: "2021-07-11 12:21:18",
          statusTime: undefined,
        }, {
          createOrg: "海尔（200000）",
          createMember: "李四(2000)",
          receiveOrg: "长虹（100000）",
          receiveMember: "",
          amount: "150000",
          status: 1,
          createTime: "2021-07-01 15:21:18",
          statusTime: undefined,
        }, {
          createOrg: "长虹（100000）",
          createMember: "张三(1000)",
          receiveOrg: "海尔（200000）",
          receiveMember: "李四(2000)",
          amount: "50000",
          status: 2,
          createTime: "2021-06-21 07:21:18",
          statusTime: "2021-07-11 07:40:28",
        },],
      },
      pageSize: 20,
      currentPage: 1,

      isVisible: false,
      addForm: {},
      orgList: [],

      //禁用今天之后的日期选项
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()//选当前时间之前的时间
        }
      }
    }
  },
  computed: {
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
    rules () {
      return {
        orgInfoId: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }]
      }
    },
  },
  methods: {
    getList () {
      let _this = this;
      card.pageCashLending({
        param: { current: _this.table.currentPage, size: _this.table.pageSize, ..._this.queryForm },
        success: res => {
          _this.table = res.data || {};
        }
      })
    },
    getOrgList () {
      let _this = this;
      org.listOrgInfoNameOrCode({
        success: res => {
          if (res.code != 200) return;
          _this.orgList = res.data;
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    addRecordVisible () {
      this.addForm = {};
      this.isVisible = true;
    },
    onSubmit (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        card.addCashLending({
          param: _this[refName],
          success: res => {
            if (res.code == 200) {
              _this.isVisible = false;
              _this.getList();
              _this.$message.success(_this.$t('operateSuccessfully'));
            }
            else {
              _this.$message.warning(_this.$t(res.msg))
            }
          }
        })
      })
    },
    updateStatus (row) {
      let _this = this;
      card.updateCashLendingStatus({
        param: { id: row.id, status: 1 },
        success: res => {
          if (res.code == 200) {
            _this.getList();
            _this.$message.success(_this.$t('operateSuccessfully'));
          }
          else {
            _this.$message.warning(_this.$t(res.msg))
          }
        }
      })
    },
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';

    this.getList();

    this.getOrgList();
  }
}
</script>

<style lang="less" scoped>
</style>